<template>
  <div class="equityWrap">
		<div class="equityContent" id="equityContent" @scroll="handleScroll($event)">
			<div class="equityNodata" v-if="equityList.length==0">
				<img src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/nodata.png"></img>
				<div>您还没有领取权益哦</div>
			</div> 
			<div v-else class="scrolldiv">
				<div class="equityInfo" v-for="(item,index) in equityList" :key="index">
					<div class="box-top">
						<div class="title">
							<img class="icon" :src="item.item_small_icon ? item.item_small_icon : item.item_background_icon ? item.item_background_icon : item.item_icon"/>
						<div class="title-text">
							<div class="text name">{{item.item_long_name}}</div>
						</div>
						<div class="title-right" v-show="detailIndex !== index">{{ item.status ? item.status : '充值成功'}}</div>
						</div>
						<div class="box-top-tip" :style="{'margin-top': item.item_exchange_no ? '-20px' : '0'}">
							<div class="text name" v-if="item.item_long_name == '5元话费券'">领取号码 : 支付宝账户</div>
							<div class="text name" v-else>领取号码 : {{item.phone_number == null ? "支付宝账户" : item.phone_number }}</div>
							<div class="text price">领取时间 : {{item.exchange_time}}</div>
							<div class="text exchangeno" v-if="item.item_exchange_no">券    码 : {{item.item_exchange_no}} | <div class="copy" @click="copyToClipboard(item.item_exchange_no)">复制</div></div>
						</div>
						<div class="box-top-more" v-show="detailIndex !== index" @click="toDetails(index)"
						:style="{'margin-top': item.item_exchange_no ? '20px' : '0'}">兑换须知
							<img class="icon" src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/right.png"/></img>
						</div>
					</div>
						
					<div class="box-bottom" @click="toDetails(index)" v-show="detailIndex == index">
						<div class="box-bottom-content">
							<div class="bottom-content-title">
								兑换须知
							</div>
							<div class="bottom-content-text">
								{{item.item_detail}}
							</div>
						</div>
						<div class="box-bottom-border"></div>
					</div>
				
				</div>
		</div>
	</div>
</div>
</template>

<script>
import VAlert from "./dialog/alert.vue";
import { record } from "@/api/huimo/receive.js";
import { mapGetters } from "vuex";

export default {
  components: {
    VAlert
  },
  data() {
    return {
      alert: {
        visibility: false,
        title: "",
        message: "",
      },
      equityList:[],
      page: 1,
      detailIndex:-1,
      cardItem:{}
    };
  },
  computed: {
    ...mapGetters(["taiyue_token", "taiyue_user_info","taiyue_wechat_open_id"]),
  },
  created() {
	this.recordList();
  },
  methods: {
    async recordList() {
      this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "加载中...",
        });
        await record(this.taiyue_token,{ 'page': this.page, 'data_type': 'exchange' })
        .then(res =>{
          if (res.code == 0) {
			this.pageCount = res.pages;
			if(this.page == 1){
				this.equityList = res.record;
			}else{
				this.equityList = this.equityList.concat(res.record)
			}
              this.$toast.clear();
          } else {
              this.$toast.clear();
          }
        }).catch(err =>{
          this.$toast.clear();
		  this.$router.replace({
            path: "/hz_huimo/receive/login",
            query: {
              wechat_open_id: this.taiyue_wechat_open_id,
            },
          });
        })
    },
	// 展开使用说明
	toDetails(index){
		if(this.detailIndex == index){
			this.detailIndex = -1;
		}else{
			this.detailIndex = index;
		}
	},
    handleSubmit(){
      this.visibility = false;
    },
	// 获取页面滚动距离
	handleScroll(e) {
		let height = document.getElementById("equityContent").offsetHeight;
		let scrollTop = e.srcElement.scrollTop || e.target.scrollTop;
		let scrollHeight = e.srcElement.scrollHeight || e.target.scrollHeight;
		if (scrollTop + height >= scrollHeight-20) {
			if (this.page == this.pageCount) {
				return
			}else{
				this.page++;
				this.recordList();
			}
		}
	},
  },
};
</script>

<style lang="scss" scoped>
.equityWrap{
		width: 100%;
		height: 100%;
		background-color: #F5F5F5;
		.equityContent{
			width: 100%;
			height: 100%;
			padding: 0 30px 30px;
			box-sizing: border-box;
			overflow-y: scroll;
			.equityNodata{
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 96vw;
				position: absolute;
				top: 200px;
				img{
					width: 354px;
					margin-top: 170px;
				}
				div{
					color: #999999;
					font-size: 28px;
					margin-top: 16px;
				}
			}
			.scrolldiv{
				width: 100%;
				height: 100%;
				.equityInfo{
					width: 100%;
					position: relative;
					margin-top: 30px;
					overflow: hidden;
					box-sizing: border-box;
					font-size: 24.271px;
					color: #999999;
					.box-top1 {
						width: 100%;
						height: 300px;
						background: url('https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/bg_01.png')  100%/cover no-repeat;
						overflow: hidden;
						color: #999999;
						.title {
							width: 100%;
							height: 138px;
							padding: 10px 60px;
							box-sizing: border-box;
							margin-top: 30px;
							.icon {
								width: 138px;
								height: 138px;
								// display: inline-block;
								float: left;
								margin-right: 39px;
								vertical-align: middle;
							}
							.title-text {
								width: calc(100% - 200px);
								height: 138px;
								float: left;
								margin-right: 18px;
								vertical-align: middle;
								.text {
									width: 100%;
									// white-space: nowrap;
									// overflow: hidden;
									// text-overflow: ellipsis;
									font-weight: 400;
									line-height: 36px;
									font-size: 26px;
								}
								.name{
									width: 100%;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									font-size: 34px;
									color: #000000;
									line-height: 72px;
								}
								.exchangeno{
									// white-space: pre-wrap;
									.copy{
										color: #FF5000;
										margin-left: 20px;
									}
								}
							}
						}
					}
					.box-top {
						width: 100%;
						height: 300px;
						background: url('https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/bg_01.png')  100%/cover no-repeat;
						overflow: hidden;
						color: #999999;
						.title {
							width: 100%;
							height: 100px;
							padding: 10px 60px;
							box-sizing: border-box;
							margin-top: 30px;
							.icon {
								width: 62px;
								height: 62px;
								// display: inline-block;
								float: left;
								margin-right: 18px;
								vertical-align: middle;
							}
							.title-text {
								width: calc(100% - 250px);
								height: 72px;
								float: left;
								margin-right: 18px;
								vertical-align: middle;
								.text {
									width: 100%;
									height: 100%;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									font-weight: 400;
									line-height: 36px;
									font-size: 26px;
								}
								.name{
									width: 100%;
									height: 100%;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									font-size: 34px;
									color: #000000;
									line-height: 72px;
								}
							}
							.title-right{
								width: 120px;
								height: 72px;
								float: right;
								font-size: 28px;
								color: #FF5000;
								line-height: 72px;
							}
						} 
						.box-top-tip{
							width: 100%;
							height: 100px;
							box-sizing: border-box;
							padding: 0px 60px;
							font-size: 26px;
							.name{
								width: 100%;
								white-space: nowrap;
								overflow: hidden;
								// text-overflow: ellipsis;
							}
							.exchangeno{
								.copy{
									color: #FF5000;
									margin-left: 20px;
								}
							}
						}
						.box-top-more{
							width: 100%;
							height: 30px;
							box-sizing: border-box;
							text-align: center;
							font-size: 26px;
							.icon{
								width: 28px;
								height: 28px;
								vertical-align: middle;
							}
						}
					}
					.box-bottom{
						width: 100%;
						margin-top: -70px;
						z-index: 2;
					}
					.box-bottom-content{
						width: 91.6%;
						min-height: 30px;
						background-color: #fff;
						box-sizing: border-box;
						margin: 0 auto;
						padding: 0 30px;
						.bottom-content-title{
							color: #333333;
							font-size: 30px;
							font-weight: 500;
							line-height: 42px;
							margin-bottom: 10px;
						}
						.bottom-content-text{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 26px;
							line-height: 42px;
							text-align: justify;
							font-style: normal;
							white-space: pre-wrap;
						}
					}
					.box-bottom-border{
						width: 100%;
						height: 50px;
						background: url('https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/bg_02.png')  100%/cover no-repeat;
					}
				}
			}
		}
	}
</style>
